import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successUpdate: false,
  data: {},
}

export const {
  requestUpdateLaneOverride,
  successUpdateLaneOverride,
  failureUpdateLaneOverride,
  clearUpdateLaneOverride,
} = createActions({
  REQUEST_UPDATE_LANE_OVERRIDE: (data) => ({
    ...defaultState,
    fetching: true,
    successUpdate: false,
    data,
  }),
  SUCCESS_UPDATE_LANE_OVERRIDE: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successUpdate: true,
  }),
  FAILURE_UPDATE_LANE_OVERRIDE: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_UPDATE_LANE_OVERRIDE: () => ({
    message: '',
    fetching: false,
    error: false,
    successUpdate: false,
  }),
})

const updateLaneOverride = handleActions(
  {
    [requestUpdateLaneOverride]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successUpdate = false
      draft.data = data
    }),
    [successUpdateLaneOverride]: produce((draft, { payload: { message, fetching, successUpdate, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successUpdate = successUpdate
      draft.data = data
    }),
    [failureUpdateLaneOverride]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearUpdateLaneOverride]: produce((draft, { payload: { message, fetching, error, successUpdate } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.successUpdate = successUpdate
    }),
  },
  defaultState
)

export default updateLaneOverride
