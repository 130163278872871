export default function (api) {
  return {
    getAllRules: () => api.get('api/rules'),
    getRules: (params) => {
      return api.get('api/rules', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    createRule: (data) => api.post('api/rules', data),
    updateRule: (ruleData) => {
      const data = { ...ruleData }
      delete data.id
      return api.patch(`api/rules/${ruleData.id}`, data)
    },
    deleteRule: (ruleId) => api.delete(`api/rules/${ruleId}`),
    switchBetaRules: () => api.patch('api/rules/switch'),
    deleteBetaRules: () => api.delete('api/rules/beta'),
    getShippingType: () => api.get('api/type-shippings'),
    getRuleTypes: () => api.get('api/type-rules'),
    getGeographicReference: () => api.get('api/type-reference-geographics'),
    getMultiSteps: (params) => {
      return api.get('api/multisteps', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    getRuleSets: (params) => {
      return api.get('api/rulesets', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    getRulesetById: (id) => api.get(`api/rulesets/${id}`),
    createMultiStep: (multiStep) => api.post('api/multisteps', multiStep),
    updateMultiStep: (multiStepData) => {
      const data = { ...multiStepData }
      delete data.id
      return api.put(`api/multisteps/${multiStepData.id}`, data)
    },
    deleteMultiStep: (multiStepId) => api.delete(`api/multisteps/${multiStepId}`),
    returnVersionMultistep: (data) => api.post(`api/multisteps/history/${data.ruleId}/version/${data.historyId}`),
    createRuleset: (ruleSet) => api.post('api/rulesets', ruleSet),
    getRuleset: (ruleSet) => api.get(`api/rulesets/${ruleSet}`),
    deleteRuleset: (ruleSetId) => api.delete(`api/rulesets/${ruleSetId}`),
    updateRuleset: (rulesetData) => {
      const data = { ...rulesetData }
      delete data.id
      return api.put(`api/rulesets/${rulesetData.id}`, data)
    },
    returnVersionRuleset: (data) => api.post(`api/rulesets/history/${data.ruleId}/version/${data.historyId}`),
    assignCustomers: (rulesetData) => api.put(`api/rulesets/${rulesetData.id}/assignCustomers`, rulesetData),
    getMasterRules: () => api.get(`/api/rules/master`),
    returnVersionRule: (data) => api.post(`api/rules/history/${data.ruleId}/version/${data.historyId}`),
    getMasterRulesByParams: (params) => {
      return api.get('api/rules/master', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    getLaneOverrides: (params) => {
      return api.get('api/lane-overrides', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    createLaneOverride: (data) => api.post('api/lane-overrides/', data),
    updateLaneOverride: (data) => api.put(`api/lane-overrides/${data.id}`, data),
    deleteLaneOverride: (id) => api.delete(`api/lane-overrides/${id}`),
    getLaneOverrideById: (id) => api.get(`api/lane-overrides/${id}`),
    getLaneOverridesByParams: (params) => {
      return api.get('api/lane-overrides/', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    getOverrideHistoryById: (id) => api.get(`api/lane-overrides/history/${id}`),
  }
}
