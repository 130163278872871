import styled from 'styled-components'
import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form' 
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'

export const StyledText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark_grey.default};
`

export const StyledCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledSteps = styled(Steps)`
  overflow-y: scroll;
  max-height: ${(props) => props.height};
  .ant-steps-item {
    margin: 0px 0px 18px 0px;
  }
  .ant-steps-item-container .ant-steps-item-tail {
    height: 150% !important;
    left: 20px !important;
    background: #ffffff !important;
    border-style: none none none dashed !important;
    border-color: #3594ff !important;
  }
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #ffffff !important;
  }
`

export const BorderSelect = styled(Select)`
  .ant-select-selector {
    border-bottom-color: #002555 !important;
    // background-color: red !important;
    max-height: 24px !important;
    // overflow: auto;
  }
  .ant-select-selection-search-input {
    // background-color: blue !important;
    max-height: 24px !important;
  }
  /* .ant-select-selection-item[title] {
    padding: 9px 0px 0px 0px; 764.52
  } */
  .ant-select-selection-search {
    padding-top: 2px !important;
    // max-height: 22px !important;
  }
`

export const StyledItem = styled(Form.Item)`
  width: 100%;
  padding: 0px; 
  .ant-form-item-label {
    padding: 0px;
    margin: 0px;
  }
  .ant-form-item-label > label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgb(110, 127, 141);
  }

  .ant-input-affix-wrapper {
    border-radius: 4px;
  }

  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-arrow {
    color: #002555;
    top: 34%;
    margin-right: 15px;
  }
`

export const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .ant-modal-header {
    border-bottom-color: ${(props) => props.theme.colors.light_grey.default};
    background-color: ${(props) => props.theme.colors.modal_white.default};
  }
  .ant-modal-body {
    overflow: auto;
    height: ${(props) => props.height};
  }

  .ant-modal-content {
    background: #f9fafe;
    border-radius: 4px 4px 0 0;
    border-width: 4px;
    border-style: none none solid;
    border-color: #3594ff;
  }

  .ant-modal-footer {
    border: none;
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-select-selector,
  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.dark_blue.default};
    border-color: ${(props) => props.theme.colors.dark_blue.default};
  }
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
