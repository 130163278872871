import React, {useEffect, useState} from "react"
import {  useDispatch, useSelector} from 'react-redux'
import { Row } from "antd"
import HistoryItems from 'components/HistoryItem'
import { requestGetOverrideHistory } from "store/duck/getLaneOverrideHistory.duck"
import { StyledSteps } from "./styles"





const LaneOverrideHistory = ({
  overrideId,
  height = '420px',
}) =>{
  // const dispatch = useDispatch()
  const {history, success, data, error, fetching, message} = useSelector((state) => state.getOverrideHistory)
  // const {success} = useSelector((state) => state.getOverrideHistory.success)
  const[overrideHistory, setHistory] = useState(history)

  useEffect(() => {
    if(success){
      setHistory(history)
    }
  },[history, success, setHistory])

  return(
    <Row>
       <HistoryItems
          data={overrideHistory}
          category='Override'
          height={height}
          id={overrideId}
          rule={false}
        />
    </Row>
  )
}





export default LaneOverrideHistory