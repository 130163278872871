import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestGetOverrideHistory,
  successGetOverrideHistory,
  failureGetOverrideHistory,
} from '../duck/getLaneOverrideHistory.duck'

function* getLaneOverrideHistoryAsync(data) {
  try {
    const response = yield call(rules.getOverrideHistoryById, data.payload.params)
    yield put(successGetOverrideHistory(response))
  } catch (error) {
    yield put(failureGetOverrideHistory(error))
  }
}

function* watchGetLaneOverrideHistoryAsync() {
  yield takeLatest(requestGetOverrideHistory, getLaneOverrideHistoryAsync)
}

export default watchGetLaneOverrideHistoryAsync
