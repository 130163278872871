import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestDeleteLaneOverride,
  successDeleteLaneOverride,
  failureDeleteLaneOverride,
} from '../duck/deleteLaneOverride.duck'

function* deleteLaneOverrideAsync(data) {
  try {
    const response = yield call(rules.deleteLaneOverride, data.payload.data)
    yield put(successDeleteLaneOverride(response))
  } catch (error) {
    yield put(failureDeleteLaneOverride(error.response.data.message))
  }
}

function* watchDeleteLaneOverrideAsync() {
  yield takeLatest(requestDeleteLaneOverride, deleteLaneOverrideAsync)
}

export default watchDeleteLaneOverrideAsync
