import Menu from 'antd/lib/menu'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { loadMultiStep } from 'store/duck/createMultiStep.duck'
import { requestDeleteMultiStep } from 'store/duck/deleteMultiStep.duck'
import { AiOutlineCheck } from 'react-icons/ai'
import { requestUpdateLaneOverride } from 'store/duck/updateLaneOverride.duck'
import { MdBlock, MdDelete, MdModeEdit, MdPauseCircleFilled } from 'react-icons/md'
import ModalDeleteRules from 'components/ModalDeleteRules'
import { loadLaneOverride } from 'store/duck/createLaneOverride.duck'
import { requestDeleteLaneOverride } from 'store/duck/deleteLaneOverride.duck'
import { StyledCol, StyledRow } from './styles'



const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }
export const EnumStatus = {
  Activated: 'Activated',
  Paused: 'Paused',
}

const ActionsDropdown = ({ record, openDrawer, status }) => {
  const dispatch = useDispatch()
  const { fetching, successDelete } = useSelector((state) => state.deleteLaneOverride)
  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Rule?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Rule has been deleted',
    actionConfirm: (some) => some,
  })

  const loadOverrideSelected = (data) => {
    dispatch(loadLaneOverride(data))
    openDrawer(true)
  }

  /*
  const [visibleDelete, setVisibleDelete] = useState(false)

  useEffect(() => {
    if (successDelete) {
      setVisibleDelete(false)
    }
  }, [successDelete])

  const openDeleteModal = () => {
    setVisibleDelete(true)
  }
  const closeDeleteModal = () => {
    return setVisibleDelete(false)
  }
  */
  const messageModal = {
    [EnumStatus.Activated]: 'activate',
    [EnumStatus.Paused]: 'pause',
  }

  const handelDeleteOverride = async (id) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this Lane Override?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'Lane Override has been deleted',
      actionConfirm: () => {
        dispatch(requestDeleteLaneOverride(id))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const handleEditOverride = async (id = '', newStatus = '') => {
    const dataToStatus = {
      id,
      status: newStatus,
    }
    setPropsModal({
      visible: true,
      message: `Are you sure you want to ${messageModal[newStatus]} this Rule?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <AiOutlineCheck {...props} />,
      textButon: messageModal[newStatus].charAt(0).toUpperCase() + messageModal[newStatus].slice(1),
      textSuccessfull: `Lane Override has been ${newStatus.toLowerCase()}`,
      actionConfirm: () => {
        dispatch(requestUpdateLaneOverride(dataToStatus))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }


  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadOverrideSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Lane Override</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => { 
              return handleEditOverride(record._id, (status === 'Paused')?'Activated':'Paused')
            }}>
            <StyledRow>
              <StyledCol>
                {(status === 'Paused')?<AiOutlineCheck size={sizeIcon} />:<MdBlock size={sizeIcon} />}
              </StyledCol>
              <StyledCol>
                <span>{(status === 'Paused')?'Unpause':'Pause'}</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => handelDeleteOverride(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Lane Override</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const getStatus = (status, expiration) => {
  if (status === "Paused") {
    return 'Paused'
  }
  const dated = new Date();
  if(expiration < dated.getTime()) {
    return 'Expired'
  }
  if(expiration < dated.setDate(dated.getDate()+1)){
    return 'Expiring'
  }
  return 'Active'
}


const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}> Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  render: (edit, record) => {
    return (
      <div style={{ display: 'block' }}>
        <ActionsDropdown
          record={record}
          openDrawer={openDrawer}
          status={getStatus(record.status, new Date(record.expirationDate))}
        />
      </div>
    )
  },
  width: 180,
  align: 'center',
})

export default actionsDropdown
