import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { failureGetLaneOverrides, requestGetLaneOverrides, successGetLaneOverrides } from 'store/duck/getLaneOverrides.duck'


function* getLaneOverridesAsync(data) {
  try {
    const response = yield call(rules.getLaneOverridesByParams, data.payload.params)
    yield put(successGetLaneOverrides(response))
  } catch (error) {
    yield put(failureGetLaneOverrides(error))
  }
}

function* watchGetLaneOverridesAsync() {
  yield takeLatest(requestGetLaneOverrides, getLaneOverridesAsync)
}

export default watchGetLaneOverridesAsync
