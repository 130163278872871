import React from 'react'
import StepItem from './utils/StepItem'
import StepItemRules from './utils/StepItemRules'
import { StyledSteps } from './styles'

export default function HistoryItems({
  data = [],
  category = '',
  height = '420px',
  rules = false,
  id = '',
  request = '',
  large,
  enableEdit = true,
}) {
  const listHistoryItems = data.map((item, index) => {
    return !rules ? (
      <StepItem HistoryItem={item} key={index} category={category} />
    ) : (
      <StepItemRules
        large={large}
        HistoryItem={item}
        key={index}
        category={category}
        id={id}
        request={request}
        enableEdit={enableEdit}
      />
    )
  })
  return (
    <>
      <StyledSteps current={3} direction="vertical" size="small" height={height}>
        {listHistoryItems}
      </StyledSteps>
    </>
  )
}
