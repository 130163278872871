import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  laneOverrides: "",
  totalRecords: 0,
  params: {},
}

export const {
  requestGetLaneOverrides,
  successGetLaneOverrides,
  failureGetLaneOverrides,
  clearGetLaneOverrides,
} = createActions({
  REQUEST_GET_LANE_OVERRIDES: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_LANE_OVERRIDES: (laneOverrides) => ({
    laneOverrides: laneOverrides.data,
    totalRecords: laneOverrides.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_LANE_OVERRIDES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_LANE_OVERRIDES: () => ({
    ...defaultState,
  }),
})

const getLaneOverrides = handleActions(
  {
    [requestGetLaneOverrides]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetLaneOverrides]: produce(
      (draft, { payload: { message, fetching, success, laneOverrides, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.laneOverrides = laneOverrides
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetLaneOverrides]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetLaneOverrides]: produce(
      (draft, { payload: { message, fetching, success, laneOverrides, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.laneOverrides = laneOverrides
        draft.totalRecords = totalRecords
      }
    ),
  },
  defaultState
)

export default getLaneOverrides
