import Text from 'antd/lib/typography/Text'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect} from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Tooltip from 'antd/lib/tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { DatePicker } from 'antd'
import notify from 'utils/notify'
import PrimarySubmit from 'components/PrimarySubmit'
import TextInput from 'components/Form/TextInput'
import SelectInput from 'components/Form/SelectInput'
import useAutocomplete from 'hooks/useAutocomplete'
import LineSelectCity from 'components/Input/InputLineSelectCity/Index'
import Select from 'antd/lib/select'
import StyledTabs from 'components/Tabs'
import { requestGetOverrideHistory } from "store/duck/getLaneOverrideHistory.duck"
import {
  requestGetZipHere,
} from 'store/duck/getZipHere.duck'
import { clearUpdateLaneOverride, requestUpdateLaneOverride } from 'store/duck/updateLaneOverride.duck'
import { requestGetLaneOverrides } from 'store/duck/getLaneOverrides.duck'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import colors from 'providers/theme/config/colorPallete'
import InputWithLabel from 'components/Form/InputWithLabel'
import DrawerItem from 'components/Form/DrawerItem'
import moment from 'moment'
import Checkbox from 'antd/lib/checkbox'
import LaneOverrideHistory from './OverrideHistory'
import { StyledModal} from './styles'
import AssignCustomers from './AssignCustomers'





const formName = 'edit-lane-override-form'
const defaultActiveKeyEditTab = '1'


const EditLaneOverrideForm = ({ onSubmit, enableEdit}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { 
    trucktypes, 
    customerSuccess,
  } = useSelector((state) => ({
    trucktypes: state.getTruckTypes.trucktypes,
    customerSuccess: state.getCustomers.success,
  }))
  const { customers } = useSelector((state) => state.getCustomers)
  const [dataDestination, setPlaceDestination, handleSelect, fetchingData] = useAutocomplete()
  const {overrideSelected} = useSelector((state) => state.createLaneOverride)
  const [assignedCustomers, setAssignedCustomers] = useState([])
  const [defaultValues, setDefaultValues] = useState({
    origin: '',
    destination: '',
    equipmentType: '',
    ValidRange: [],
    exparation: '',
    customers: [],
    value: '',
  })
  const [specialServices, setSpecialServices] = useState(false)
  const [customerMargin, setCustomerMargin] = useState(true)

  const handleSubmit = (formProps) => {
    onSubmit({ ...formProps, customers: assignedCustomers, addSpecialServices: specialServices, addCustomerMargin: customerMargin })
  }

  const handleCity = (value, index) => {
    dispatch(requestGetZipHere(value, index))
  }
  useEffect(() => {
    dispatch(requestGetCustomers())
    dispatch(requestGetOverrideHistory(overrideSelected._id))
    // if edit user and success set form values here
    const tempValues = {
      prevOrigin: overrideSelected.originCity??'',
      prevDestination: overrideSelected.destinationCity??'',
      equipmentType: overrideSelected.equipmentType._id??'',
      startDate: overrideSelected.ValidRange.length>1?moment.utc(overrideSelected.ValidRange[0]):'',
      endDate: overrideSelected.ValidRange.length>1?moment.utc(overrideSelected.ValidRange[1]):'',
      expirationDate: moment.utc(overrideSelected.expirationDate)??'',
      customers: overrideSelected.customers??[],
      value: overrideSelected.value??'',
    }
    setCustomerMargin(overrideSelected.addCustomerMargin??true)
    setSpecialServices(overrideSelected.addSpecialServices??false)
    setDefaultValues(tempValues)
  }, [dispatch, overrideSelected]);
    useEffect(() => {
      form.setFieldsValue(defaultValues)
    }, [defaultValues, form])

  const newDatePicker = (value, onChange, disabled) => (
    <>
      {disabled && 
        <DatePicker
          style={{ width: '100%' }}
          placeholder=""
          size="large"
          suffixIcon={null}
          value={value}
          onChange={()=>{}}
        />
      }
      {!disabled && 
        <DatePicker
          style={{ width: '100%' }}
          placeholder=""
          size="large"
          suffixIcon={null}
          value={value}
          onChange={onChange}
        />
      }
    </>
  )
  const ControlInput = ({ value, onChange, hasValue, children }) => {
    useEffect(() => {
      hasValue(value)
    }, [hasValue, value])
    return children({ value, onChange })
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        onFinish={handleSubmit}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row gutter={[26, 10]}>
          <Col span={24}>
            <div
              style={{
                //
                fontSize: '15px',
                color: colors.dark_grey.default,
                // ,
              }}
            >
              {` (The 3 digits of the zipcode are used to determine locations)`}
            </div>
          </Col>
          <Col span={12}>
            <TextInput disabled label="Previous Origin" name="prevOrigin"/>
          </Col>
          <Col span={12}>
            <LineSelectCity
              dataTestid="Origin"
              name="originCity"
              label="Origin"
              disabled={!enableEdit}
              onSearch={setPlaceDestination}
              options={dataDestination}
              onChange={handleCity}
              index={0}
              fetchingData={fetchingData}
            />
          </Col>
          <Col span={12}>
            <TextInput disabled label="Previous Destination" name="prevDestination"/>
          </Col>
          <Col span={12}>
            <LineSelectCity
              dataTestid="Destination"
              name="destinationCity"
              label="Destination"
              disabled={!enableEdit}
              onSearch={setPlaceDestination}
              options={dataDestination}
              onChange={handleCity}
              index={0}
              fetchingData={fetchingData}
            />
          </Col>
          <Col span={24}>
            <SelectInput
              label="Equipment *"
              name="equipmentType"
              disabled={!enableEdit}
              rules={[{ required: true, message: 'Please select an equipment' }]}
            >
              {trucktypes.map((truck) => (
                <Select.Option key={truck._id} value={truck._id}>
                  {truck.name}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col span={24}>
            <Row gutter={10}>
              <Col span={12}>
                <InputWithLabel label="Start date*">
                  {(hasValue) => (
                    <DrawerItem name="startDate" rules={[{ required: true, message: 'Please select a start date' }]}>
                      <ControlInput hasValue={hasValue} disabled={!enableEdit}>
                        {({ value, onChange }) => newDatePicker(value, onChange)}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
              <Col span={12}>
                <InputWithLabel label="End date*">
                  {(hasValue) => (
                    <DrawerItem name="endDate" rules={[{ required: true, message: 'Please select a end date' }]}>
                      <ControlInput hasValue={hasValue} disabled={!enableEdit}>
                        {({ value, onChange }) => newDatePicker(value, onChange)}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <InputWithLabel label="Exparation Date*">
                  {(hasValue) => (
                    <DrawerItem name="expirationDate" rules={[{ required: true, message: 'Please select an exparation date' }]}>
                      <ControlInput hasValue={hasValue} disabled={!enableEdit}>
                        {({ value, onChange }) => newDatePicker(value, onChange)}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
          </Col>
          <Col span={24}>
          { customerSuccess &&
            <AssignCustomers
              customers={customers}
              resetFormValue={() => form.setFieldsValue({ defaultCustomer: '' })}
              handleValues={setAssignedCustomers}
              loadCustomers={overrideSelected.customers}
            />}
          </Col>
          <Col span={12}>
            <Checkbox
              checked={specialServices}
              onChange={() => {
                setSpecialServices((prev) => !prev)
              }}
              disabled={!enableEdit}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Add Specal Services</Text>
            </Checkbox>
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={customerMargin}
                onChange={() => {
                  setCustomerMargin((prev) => !prev)
                }}
              >
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Add Customer Margin</Text>
              </Checkbox>
              <Tooltip title="This will allow customer flat and margin to be applied on top of this lane override.">
                <AiOutlineInfoCircle style={{ marginLeft: 8, color: '#545454', fontSize: 16 }} />
              </Tooltip>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                //
                fontSize: '15px',
                color: colors.dark_grey.default,
                // ,
              }}
            >
              {` (Amount regards the flat rate a lane should be priced at ignoring the pricing engine)`}
            </div>
          </Col>
          <Col span={24}>
            <TextInput disabled={!enableEdit} label="Amount *" name="value" rules={[{
              required: true,
              message: 'This field is required',
              
            }]} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

const EditLaneOverrideModal = ({
  visible,
  onCloseModal,
  enableEdit = true,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {overrideSelected} = useSelector((state) => state.createLaneOverride)
  const {
    successUpdate: successUpdateLaneOverride,
    error: errorUpdateLaneOverride,
    message: errorMessageUpdateLaneOverride,
  } = useSelector((state) => state.updateLaneOverride)
  const {close, setClose} = useState(false)
  const [activeKeyEditTab, setActiveKeyEditTab] = useState(defaultActiveKeyEditTab)

  const difference = (prev,next) => {
    const diff = {}
    if(prev.ValidRange.length >1 && next.startDate && next.endDate) {
      if(moment.utc(prev.ValidRange[0]).format('YYYY-MM-DD') !== moment.utc(next.startDate).format('YYYY-MM-DD') || moment.utc(prev.ValidRange[1]).format('YYYY-MM-DD') !== moment.utc(next.endDate).format('YYYY-MM-DD')) {
        diff.ValidRange = [moment.utc(next.startDate).format('YYYY-MM-DD'), moment.utc(next.endDate).format('YYYY-MM-DD')]
      }
    }else if(prev.ValidRange.length <2 && next.startDate && next.endDate) {
      diff.ValidRange = [moment.utc(next.startDate).format('YYYY-MM-DD'), moment.utc(next.endDate).format('YYYY-MM-DD')]
    }
    if(prev.value !== next.value) {
      diff.value = next.value
    }
    if(moment.utc(prev.expirationDate).format('YYYY-MM-DD') !== moment.utc(next.expirationDate).format('YYYY-MM-DD')) {
      diff.expirationDate = moment.utc(next.expirationDate).format('YYYY-MM-DD')
    }
    if(prev.equipmentType._id !== next.equipmentType) {
      diff.equipmentType = next.equipmentType
    }
    if(next.originCity && prev.originCity !== next.origin) {
      diff.originCity = next.originCity
    }
    if(next.destinationCity && prev.destinationCity !== next.destination) {
      diff.destinationCity = next.destinationCity
    }
    if(next.addCustomerMargin !== prev.addCustomerMargin) {
      diff.addCustomerMargin = next.addCustomerMargin
    }
    const prevCust = prev.customers.map((cust) => {
      return {order: cust.order,
              id: cust.id._id}
    })
    if(prevCust.length !== next.customers.length) {
      diff.customers = next.customers
    }else{
      for(let i = 0; i < prevCust.length; i+=1) {
        if(prevCust[i].id !== next.customers[i].id) {
          diff.customers = next.customers
          break
        }
      }
    }
    if(next.addSpecialServices !== prev.addSpecialServices) {
      diff.addSpecialServices = next.addSpecialServices
    }
    // enter logic for customers here
    diff.id = overrideSelected._id
    return diff
  }
  
  const handleComplete = (rawDataToSubmit) => {

    // debugger
    delete rawDataToSubmit.defaultCustomer
    delete rawDataToSubmit.prevOrigin
    delete rawDataToSubmit.prevDestination
    Object.keys(rawDataToSubmit).forEach((key) =>
      rawDataToSubmit[key] === undefined ? delete rawDataToSubmit[key] : {}
    )
    // console.log(JSON.stringify(overrideSelected.customers,null,2)+ JSON.stringify(rawDataToSubmit.customers,null,2))
    setLoading(true)
    dispatch(requestUpdateLaneOverride(difference(overrideSelected,rawDataToSubmit)))
    setClose(true)
  }
  useEffect(() => {
    if (close) {
      onCloseModal()
      setClose(false)
    }
  }, [close, onCloseModal, setClose])

  useEffect(() => {
    if (successUpdateLaneOverride || errorUpdateLaneOverride) {
      if (successUpdateLaneOverride) {
        notify('success', 'Lane Override edited successfully!')
        onCloseModal()

        dispatch(requestGetLaneOverrides())
      }
      
      if (errorUpdateLaneOverride) {
        notify('error', `Lane Override edit Error! ${errorMessageUpdateLaneOverride}`)
      }

      setLoading(false)
      dispatch(clearUpdateLaneOverride())
    }
  }, [errorUpdateLaneOverride, errorMessageUpdateLaneOverride, successUpdateLaneOverride, dispatch, onCloseModal])

  return (
   
    <StyledModal
      title="Edit Lane Override"
      width={500}
      visible={visible}
      onCancel={onCloseModal}
      afterClose={() => {
        setActiveKeyEditTab(defaultActiveKeyEditTab) 
      }}
      bodyStyle={{ padding: '10px 25px 0px' }}
      centered
      footer={
        <div>
          <PrimarySubmit label='Submit' formName={formName} loading={loading} />
        </div>
      }
      destroyOnClose
    >
      <StyledTabs
        activeKey={activeKeyEditTab}
        onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
        tabBarStyle={{ width: '100%', marginBottom: 10 }}
      >
        <StyledTabs.TabPane tab="Rules Info" key="1">
          <EditLaneOverrideForm enableEdit={enableEdit} onSubmit={handleComplete}/>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="History" key="2">
          <LaneOverrideHistory laneOverride={overrideSelected._id} />
        </StyledTabs.TabPane>
      </StyledTabs>
    </StyledModal>
  )
}
export default EditLaneOverrideModal
