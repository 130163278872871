import React, {  useState, useCallback, useEffect } from 'react'
import {  useDispatch, useSelector} from 'react-redux'
import FilterLaneOverrides from 'components/Form/FilterLaneOverrides'
import Table from 'components/Table'
import Tooltip from 'antd/lib/tooltip' 
import Typography from 'antd/lib/typography'
import { requestGetLaneOverrides } from 'store/duck/getLaneOverrides.duck'
import moment from 'moment'
import { clearGetOverrideHistory } from 'store/duck/getLaneOverrideHistory.duck'
import actionsDropdown from './utils/ActionsDropdown'
import RulesContainer from '../styles'
import StyledBadge from './styles'
import CreateLaneOverrideModal from './utils/CreateLaneOverride/CreateLaneOverrideModal'
import EditLaneOverrideModal from './utils/EditOverride/EditLaneOverrideModal'




const { Text } = Typography

const LaneOverrides = ({ enableEdit = true, displayFilter = true}) => {
  const dispatch = useDispatch()
  const [params, setParams] = useState({ text: '', page: 1, status: '', limit: 100 })
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const { laneOverrides, success, error, fetching, totalRecords } = useSelector((state) => state.getLaneOverrides)
  const {successDelete} = useSelector((state) => state.deleteLaneOverride)
  const {successUpdate} = useSelector((state) => state.updateLaneOverride)
  // //
   const { login } = useSelector((state) => ({ login: state.login.data.roleId.name }))
  const [overridesData, setOverridesData] = useState(laneOverrides)
  const [visible, setVisible] = useState(false);


  /** Possible values are Paused, Active, Expired, and Expiring
  */
  const getStatus = (status, expiration) => {
    if (status === "Paused") {
      return 'Paused'
    }
    const dated = new Date();
    if(expiration < dated.getTime()) {
      return 'Expired'
    }
    if(expiration < dated.setDate(dated.getDate()+1)){
      return 'Expiring'
    }
    return 'Active'
  }



  const columns = () => {
    // name: City, St, City, St, Truck type
    // Customer List(copy from multistep rules conditionals)
    // Rate(value)
    // expiration date(normalize to MM-DD-YYYY format)
    // status(convert to corrosponding badges with colors)
    //     - Active/paused/expired
    // Actions Dropdown(Edit/pause or unpause/ delete)
    return [
      // name from compound values here
      {
        title: <div style={{ fontSize: '16px' }}>Name</div>,
        dataIndex: 'originCity',
        key: 'originCity',
        render: (originCity, record) => {
          const name = `${originCity.split(" - ")[0]} - ${record.destinationCity.split(" - ")[0]}: ${record.equipmentType.name}`;
          return <div style={{ fontSize: '16px', display: 'flex' }}>{name}</div>
        },
        align: 'left',
      },
      {
        title: <div style={{ fontSize: '16px' }}> # Customers</div>,
        key: 'customers',
        render: (text, record) => {
          if (record.customers) { 
            return (
              <Tooltip
                placement="bottom"
                title={
                  record.customers.length > 0
                    ? record.customers.map((v ) => <div key={v._id}>{v.id.name}</div>)
                    : null
                }
                color="#3594FF"
              >
                <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
                  {record.customers.length}
                </Text>
              </Tooltip>
            )
          }
          return '0'
        },
        width: 150,
        align: 'left',
      },
      {
        title: <div style={{ fontSize: '16px' }}>Rate</div>,
        dataIndex: 'value',
        render: (value) => {
          return <div style={{ fontSize: '16px', display: 'flex' }}>${value}</div>
        },
        align: 'left',
      },
      {
        title: <div style={{ fontSize: '16px' }}>Exparation</div>,
        dataIndex: 'expirationDate',
        render: (date) => {
          return <div style={{ fontSize: '16px', display: 'flex' }}>{moment(date).format('MM/DD/YYYY HH:mm')}</div>
        },
        align: 'left',
      },
      {
        title: <div style={{ fontSize: '16px' }}> Status</div>,
        dataIndex: 'status',
        render: (status, record) => {
          return (
            <div style={{ display: 'flex' }}>
              <StyledBadge variant={getStatus(status, new Date(record.expirationDate))} />
            </div>
          )
        },
        width: 175,
      },
      {
        ...actionsDropdown(dispatch, setVisible),
      },
      // actions dropdown
    ]
  }



  useEffect(() => {
    dispatch(requestGetLaneOverrides(params))
  }, [params, dispatch])
  useEffect(() => {
    if(successDelete || successUpdate) {
      dispatch(requestGetLaneOverrides(params))
    }
  }, [successDelete, successUpdate, dispatch, params]);

  useEffect(() => {
    setOverridesData(laneOverrides)
  }, [laneOverrides])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  useEffect(() => {
    if(!visible){
      dispatch(clearGetOverrideHistory());
    }
  }, [visible, dispatch])

  return(
    <RulesContainer>
      <CreateLaneOverrideModal visible={isOpenCreateModal} onCloseModal={() => setIsOpenCreateModal(false)}/>
      <EditLaneOverrideModal visible={visible} onCloseModal={() => setVisible(false)}/>
      {
        !displayFilter ? null : (
          <FilterLaneOverrides
            setIsOpenCreateModal={setIsOpenCreateModal}
            enableEdit={login === 'Admin'}
          />
        )
      }
      <Table
        rule="multistep"
        enableEdit={enableEdit}
        columns={columns()}
        data={overridesData}
        loading={fetching}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRecords }}
        updatedPagination
        onChange={handleTableChange}
        limitPerPage={100}
        // TODO: 
        dispatch={dispatch}
        status={params.status}
        addHeight={-30}
      />
    </RulesContainer>
  );
}
/*
request={(nextData) => dispatch(requestUpdateMultiStep(nextData))}
              pauseRequest={requestUpdateStatusMultiStep}
        openDeleteModal={showModalDelete}
        handleData={handleData}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.rules ?? []}
            close={closeModalDelete}
            componentBody={textBody}
            onClick={() => multipleDelete()}
            fetching={fetchingDelete}
          />
        }
*/

export default LaneOverrides;