import { combineReducers } from 'redux'
import activateUser from './activateUser.duck'
import activateSpecialService from './activateSpecialService.duck'
import assignTrustPercentage from './assignTrustPercentage.duck'
import createCustomer from './createCustomer.duck'
import createMultiStep from './createMultiStep.duck'
import createPricingLog from './createPricingLog.duck'
import createRate from './createRate.duck'
import createRule from './createRule.duck'
import createRuleSet from './createRuleSet.duck'
import createSpecialService from './createSpecialService.duck'
import createSuggestion from './createSuggestion.duck'
import createTruckType from './createTruckType.duck'
import createUser from './createUser.duck'
import deleteMultiStep from './deleteMultiStep.duck'
import deleteSpecialService from './deleteSpecialService.duck'
import deleteRule from './deleteRule.duck'
import deleteRate from './deleteRate.duck'
import deleteRuleset from './deleteRuleset.duck'
import deleteSuggestion from './deleteSuggestion.duck'
import editUser from './editUser.duck'
import forgotPassword from './forgotPassword.duck'
import getAllRates from './getAllRates.duck'
import getAllRules from './getAllRules.duck'
import getAllCustomer from './getAllCustomer.duck'
import getCustomers from './getCustomers.duck'
import getDatHistory from './getDatHistory.duck'
import getGeographicReference from './getGeographicReference.duck'
import getMultiSteps from './getMultiSteps.duck'
import getRoles from './getRoles.duck'
import getRouteData from './getRouteData.duck'
import getRate from './getRate.duck'
import getRules from './getRules.duck'
import getMasterRules from './getMasterRules.duck'
import getRuleSets from './getRuleSets.duck'
import getRulesetById from './getRulesetById.duck'
import getRuleType from './getRuleType.duck'
import getShippingType from './getShippingType.duck'
import getSingleUser from './getSingleUser.duck'
import getSpecialServices from './getSpecialServices.duck'
import getTruckTypeList from './getTruckTypeList.duck'
import getTruckTypes from './getTruckTypes.duck'
import getTrustPercentages from './getTrustPercentages.duck'
import getUsers from './getUsers.duck'
import login from './login.duck'
import resetPassword from './resetPassword.duck'
import saveSpecialServices from './saveSpecialServices.duck'
import updateMultiStep from './updateMultiStep.duck'
import updateSpecialService from './updateSpecialService.duck'
import updateTruckType from './updateTruckType.duck'
import updateTrustPercentage from './updateTrustPercentage.duck'
import updateRuleset from './updateRuleset.duck'
import updateRule from './updateRule.duck'
import assignCustomers from './assignCustomers.duck'
import userEditAdmin from './userEditAdmin.duck'
import getAutocompleteHere from './getAutocompleteHere.duck'
import getCityFromZip from './getCityFromZip.duck'
import validateZipcode from './validateZipcode.duck'
import getZipcodes from './getZipcodes.duck'
import deactivateUser from './deactivateUser.duck'
import getZipAutoComplete from './getZipAutoComplete.duck'
import getZipHere from './getZipHere.duck'
import saveQuote from './saveQuote.duck'
import editCustomer from './editCustomer.duck'
import getCustomer from './getCustomer.duck'
import activateCustomer from './activateCustomer.duck'
import deleteCustomer from './deleteCustomer.duck'
import updateAvatar from './updateAvatar.duck'
import getDownloadMultipleRates from './getDownloadMultipleRates.duck'
import getStates from './getStates.duck'
import validateState from './validateState.duck'
import defaultCustomer from './defaultCustomer.duck'
import getDefaultCustomer from './getDefaultCustomer.duck'
import sendEmail from './sendEmail.duck'
import resendInvite from './resendInvite.duck'
import getOverrideHistory from './getLaneOverrideHistory.duck'
import getHistory from './getHistory.duck'
import getRuleHistory from './getRuleHistory.duck'
import updateStatusMultiStep from './updateStatusMultiStep.duck'
import updateStatusRule from './updateStatusRule.duck'
import deleteBulkRuleset from './deleteBulkRuleset.duck'
import deleteBulkCustomer from './deleteBulkCustomer.duck'
import messageSnackBar from './messageSnackBar.duck'
import getMultiStepsHistory from './getMultiStepsHistory.duck'
import getRuleSetsHistory from './getRuleSetsHistory.duck'
import getLaneOverrides from './getLaneOverrides.duck'
import deleteTruckType from './deleteTruckType.duck'
import getSpecialServiceHistory from './getSpecialServiceHistory.duck'
import getTruckTypesHistory from './getTruckTypeHistory.duck'
import returnVersionRule from './returnVersionRule.duck'
import returnVersionMultistep from './returnVersionMultistep.duck'
import returnRuleset from './returnRuleSet.duck'
import editSuggestion from './editSuggestion.duck'
import updateBulkFuelPrice from './updateBulkFuelPrice.duck'
import createModalRate from './getModalRate.duck'
import markets from './getMarkets.duck'
import patchQuote from './patchQuote.duck'
import getStatistics from './getStatistics.duck'
import getTotalStatistics from './getTotalStatistics.duck'
import createLaneOverride from './createLaneOverride.duck'
import updateLaneOverride from './updateLaneOverride.duck'
import deleteLaneOverride from './deleteLaneOverride.duck'
import saveStops from './saveStops.duck'
import getGsRate from './getGsRate.duck'
import getMasterRulesByParams from './getMasterRulesByParams.duck'
import deleteBetaRules from './deleteBetaRules.duck'
import SwitchBetaRules from './switchBetaRules.duck'
import getRulesByCustomer from './getRulesByCustomer.duck'
import getMultiStepsByCustomer from './getMultiStepsByCustomer.duck'
import saveSelectedCustomer from './saveSelectedCustomer.duck'
import refreshToken from './refreshToken.duck'
import getSuggestions from './getSuggestions.duck'
import createModalSuggestion from './getModalSuggestion.duck'

const rootReducer = combineReducers({
  assignTrustPercentage,
  getTrustPercentages,
  getRules,
  getMasterRules,
  getMultiSteps,
  getRuleSets,
  getRulesetById,
  getAllRates,
  getAllRules,
  getAllCustomer,
  getCustomers,
  getSpecialServices,
  getTruckTypes,
  getTruckTypeList,
  getUsers,
  createCustomer,
  createPricingLog,
  createSpecialService,
  createTruckType,
  createMultiStep,
  createRuleSet,
  createRate,
  createSuggestion,
  login,
  createLaneOverride,
  updateLaneOverride,
  forgotPassword,
  resetPassword,
  getRoles,
  deleteLaneOverride,
  getRouteData,
  getRate,
  createUser,
  createRule,
  getGeographicReference,
  getRuleType,
  getShippingType,
  getSingleUser,
  getSuggestions,
  getOverrideHistory,
  editUser,
  saveSpecialServices,
  getDatHistory,
  updateMultiStep,
  updateSpecialService,
  updateTruckType,
  updateTrustPercentage,
  updateRuleset,
  updateRule,
  assignCustomers,
  deleteMultiStep,
  deleteSpecialService,
  deleteTruckType,
  deleteRule,
  deleteRuleset,
  deleteRate,
  deleteSuggestion,
  activateUser,
  userEditAdmin,
  getAutocompleteHere,
  getCityFromZip,
  validateZipcode,
  getZipcodes,
  deactivateUser,
  getZipAutoComplete,
  getZipHere,
  saveQuote,
  editCustomer,
  editSuggestion,
  getCustomer,
  activateCustomer,
  deleteCustomer,
  updateAvatar,
  getDownloadMultipleRates,
  getStates,
  getLaneOverrides,
  validateState,
  defaultCustomer,
  getDefaultCustomer,
  sendEmail,
  resendInvite,
  getHistory,
  getRuleHistory,
  updateStatusMultiStep,
  updateStatusRule,
  deleteBulkRuleset,
  deleteBulkCustomer,
  messageSnackBar,
  getMultiStepsHistory,
  getRuleSetsHistory,
  getSpecialServiceHistory,
  activateSpecialService,
  getTruckTypesHistory,
  returnVersionRule,
  returnVersionMultistep,
  returnRuleset,
  updateBulkFuelPrice,
  createModalRate,
  markets,
  patchQuote,
  getStatistics,
  getTotalStatistics,
  saveStops,
  getGsRate,
  getMasterRulesByParams,
  deleteBetaRules,
  SwitchBetaRules,
  getRulesByCustomer,
  getMultiStepsByCustomer,
  saveSelectedCustomer,
  createModalSuggestion,
  refreshToken
})

export default rootReducer
