import React from 'react'
import Modal from 'components/Modal'
import Row from 'antd/lib/row'
import Timeline from 'antd/lib/timeline'
import Col from 'antd/lib/col'
import StyledTabs from 'components/Tabs'
import returnStops from 'utils/returnStops'
import { Fuel } from 'assets/svg'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'
import Details from 'containers/dashboard/CarrierCost/Utils/Details'
import { getMilles } from 'utils/get'
import { TitleValue, TextValue, SmallBox } from './styles'

const convertToNumber = (string = '', fixed = '') => {
  if (typeof string === 'string') {
    return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  }
  return 0
}

export default function ModalQuote({ visible = false, onCancel = () => '', data = {} }) {
  const margin = data?.customerRateInfo?.margin / 100
  const flat = data?.customerRateInfo?.flat

  const { details } = data || {}
  const { base_rate: base, ruleAdjustment, multiStepAdjustment, internal_adjustment: gsDatalake } = details || {}
  const { datRate, gsRate, gsPredictionRate, laneOverride } = base || {}
  const totalRulesAdjustments =
    removeRulesNoFlatAndMarginZero(ruleAdjustment).length +
    removeRulesNoFlatAndMarginZero(multiStepAdjustment).length



  const validateDATTooltip = () => {
    if (datRate && datRate.datLow) {
      return datRate.datLow.rate === '$0.00'
    }
    return true
  }
  const validateGS = () => {
    if (gsRate) {
      return gsRate.rate === '$0.00'
    }
    return true
  }

  const inNumber = (string = '', fixed = '') => {
    if (typeof string === 'string') {
      return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
    }
    return 0
  }
  const datRateTotal = validateDATTooltip() ? 0 : inNumber(datRate.total)
  const gsRateTotal = validateGS() ? 0 : inNumber(gsRate?.total)
  const gsPredictionRateTotal = validateGS() ? 0 : inNumber(gsPredictionRate?.total ?? '')
  const rateCost = convertToNumber(details?.rateCost ?? '')
  const estPercentage = `${(((data?.rate - rateCost) / rateCost) * 100).toFixed(1)}` 
  const milles = getMilles(data?.totalMilles,data?.stops) 

  return (
    <Modal onCancel={onCancel} visible={visible} title="View Rate" width="550px">
      <StyledTabs defaultActiveKey="1" tabBarStyle={{ width: '100%', marginBottom: 10 }}>
        <StyledTabs.TabPane tab="Info" key="1">
          <Row gutter={[16, 8]} style={{ paddingTop: '15px' }}>
            <Col span={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <SmallBox color="#f4f4f5">
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Base Markup</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>${data?.customerRateInfo?.flat}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>% Markup</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.customerRateInfo?.margin}%</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Weight</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.weight}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Mileage</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{milles}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Customer</TitleValue>
                      </Col>
                      <Col
                        span={24}
                        style={{ display: 'flex', justifyContent: 'center', textOverflow: 'ellipsis' }}
                      >
                        <TextValue style={{ color: '#464851' }}>{data?.customer?.name}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>

                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Pick up Date</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.pickupDate}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>

                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Delivery Date</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.deliveryDate}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              <Row>
                <Col
                  span={24}
                  style={{ display: 'flex', backgroundColor: 'white', padding: '10px', borderRadius: '4px' }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex' }}>
                      <TitleValue>Rate #</TitleValue>
                      <TextValue>{data?._id}</TextValue>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Equipment Type</TextValue>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>{data?.truckTypeId?.name}</TextValue>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Carrier Cost</TextValue>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>{data?.details?.rateCost}</TextValue>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Customer Rate</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>${data?.rate}</TextValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              marginBottom: '2px',
                            }}
                          >
                            <TextValue style={{ fontSize: '11px', color: 'black' }}>
                              {data?.LH ? `$${data?.LH} (LH)` : ''}
                            </TextValue>
                            {data?.LH ? (
                              <img
                                alt=""
                                style={{ marginLeft: '3px', width: '14px', height: '14px', paddingBottom: '1px' }}
                                src={Fuel}
                              />
                            ) : (
                              ''
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Margin ($)</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>
                            $
                            {data?.details?.rateCost
                              ? (convertToNumber(data?.details?.rateCost) * margin + flat).toFixed(2)
                              : '0'}
                          </TextValue>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Percentage (%)</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>{data?.details?.rateCost ? estPercentage : ''}%</TextValue>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24} style={{ marginTop: '10px' }}>
                  <TitleValue style={{ paddingBottom: '10px' }}>Lane</TitleValue>
                  <div style={{ padding: '0px 20px' }}>
                    <Timeline style={{ fontSize: '14px' }}>
                      {data.lane &&
                        returnStops(data).map((item) => <Timeline.Item key={item}>{item}</Timeline.Item>)}
                    </Timeline>
                  </div>
                </Col>
                {laneOverride?.value &&
                  <Col span={24}>
                    <SmallBox color="#e6f7ff" style={{ marginTop: '20px', padding: '12px' }}>
                      <TextValue style={{ color: '#1890ff', textAlign: 'center' }}>
                        This quote has had a lane override applied and is not effected by the market details
                      </TextValue>
                    </SmallBox>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="Details" key="2">
          <Details
            datRate={datRate}
            gsRate={gsRate}
            gsPredictionRate={gsPredictionRate}
            gsDatalake={gsDatalake}
            rateBasic={{ ...details, extraStops: data?.totalStops ?? 0 }}
            datRateTotal={datRateTotal}
            gsRateTotal={gsRateTotal}
            gsPredictionRateTotal={gsPredictionRateTotal}
            ruleAdjustments={ruleAdjustment}
            multiSteptAdjustments={multiStepAdjustment}
            totalRulesAdjusments={totalRulesAdjustments}
            specialServices={data.specialServices ?? []}
            laneOverride={laneOverride}
          />
        </StyledTabs.TabPane>
      </StyledTabs>
    </Modal>
  )
}
