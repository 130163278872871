import React, { useState, useEffect } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import { ArrowDownIcon } from 'assets/svg'
import FloatLabel from 'components/Label/FloatLabel'
import CustomSelect from 'components/CustomSelect'
import { BsPlusSquareFill } from 'react-icons/bs'
import { formatCustomer } from 'utils/formatValues'
import Select from 'antd/lib/select'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { requestSaveSelectedCustomer } from 'store/duck/saveSelectedCustomer.duck'
import { requestGetAllCustomer } from 'store/duck/getAllCustomer.duck'
import { requestGetLaneOverrides } from 'store/duck/getLaneOverrides.duck'
import SelectInput from 'components/Form/SelectInput'
import { requestGetStates } from 'store/duck/getStates.duck'
import StyledItem from '../StyledItem/Index'
import StyledOptions from '../StyledOptions'



export default function FilterLaneOverrides({
  setIsOpenCreateModal = () => '',
  enableEdit = true,
}) {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const [params, setParams] = useState({ text: '', page: 1, status: '', customer: [], limit: 100 })
  const dispatch = useDispatch()
  const [totalCustomer, setTotalCustomers] = useState([])
  const { states } = useSelector((state) => ({states: state.getStates.data}))
  const { trucktypes } = useSelector((state) => ({
    trucktypes: state.getTruckTypes.trucktypes
  }))
  const { selectedCustomer} = useSelector((state) => (
    state.saveSelectedCustomer
  ))
  const { customers } = useSelector((state) => ({
    customers: state.getAllCustomer.data,
  }))

  useEffect(() => {
    dispatch(requestGetStates(''))
  }, [dispatch])

  useEffect(() => {
    setTotalCustomers(formatCustomer(customers))
  }, [customers])


  useEffect(() => {
    const include = params.customer.indexOf(selectedCustomer)
    if (selectedCustomer !== "" && include < 0) {
      setParams({ ...params, customer: [selectedCustomer] })
      form.setFieldsValue({ customer: selectedCustomer })
    }
  }, [params, form, selectedCustomer])



  useEffect(() => {
    dispatch(requestGetLaneOverrides(params))
  }, [params, dispatch, enableEdit])

  // update this with laneOverride DataStore
  const handleValuesChange = (values) => {
    const status = values.status ?? params.status
    const objectParams = {
      ...params,
      status: status !== 'All' && status !== 'Deactivated' ? status : undefined,
      truckTypeId: values.equipmentType,
      originState: values.origin,
      destinationState: values.destination,
      customer: values.customer,
      active: status === 'Deactivated' ? true: undefined,
    }
    const customer = values?.customer ?? ''
    if (customer !== '') {
      objectParams.customer = [customer]
      dispatch(requestSaveSelectedCustomer(customer))
    }
    else {
      objectParams.customer = []
      if (selectedCustomer !== "") dispatch(requestSaveSelectedCustomer(""))
    }
    setParams(objectParams)
  }


  const handleCreateLaneOverride = () => {
    setIsOpenCreateModal(true)
    // dispatch(addLaneOverride())
  }

  const compare = (a, b) => {
    if (a.value < b.value) return -1
    if (a.value > b.value) return 1
    return 0
  }

  return (
    <>
      <Form
        layout="vertical"
        name="filter-Lane-Overrides-form"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          status: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 4 : undefined} xs={!xl ? 24 : undefined}>
            <SelectInput
              label="Equipment"
              name="equipmentType"
            >
              <Select.Option key=''>
                  All
              </Select.Option>
              {trucktypes.map((truck) => (
                <Select.Option key={truck._id} value={truck._id}>
                  {truck.name}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col flex={xl ? 4 : undefined} xs={!xl ? 24 : undefined}>
            <SelectInput
              label="Origin State"
              name="origin"
            >
              <Select.Option key=''>
                All
              </Select.Option>
              {[...states].sort((a,b) => compare(a,b)).map((state) => (
                <Select.Option key={state.abbreviation} value={state.abbreviation}>
                  {state.value}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col flex={xl ? 4 : undefined} xs={!xl ? 24 : undefined}>
            <SelectInput
              label="Destination State"
              name="destination"
            >
              <Select.Option key=''>
                All
              </Select.Option>
              {[...states].sort((a,b) => compare(a,b)).map((state) => (
                <Select.Option key={state.abbreviation} value={state.abbreviation}>
                  {state.value}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col span={4} xs={5}>
            <FloatLabel label="Customer" show>
              <StyledItem name="customer">
                <CustomSelect
                  allowClear
                  onSearch={(value) => 
                    dispatch(requestGetAllCustomer({ text: value, active: true }))
                  }
                  optionFilterProp="label"
                  keysort="label"
                  showSearch
                  size="large"
                  suffixIcon={<ArrowDownIcon />}
                  options={totalCustomer}
                />
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="status">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Activated" value="Activated">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Deactivated" value="Deactivated">
                    Expired
                  </StyledOptions>
                  <StyledOptions key="Paused" value="Paused">
                    Paused
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          {enableEdit && (
            <Col flex="none">
              <Form.Item>
                <ButtonPrimary
                  icon={<BsPlusSquareFill size="16px" />}
                  height="40px"
                  onClick={handleCreateLaneOverride}
                >
                  New Lane Override
                </ButtonPrimary>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
     
    </>
  )
}
/*
 <CreateLaneOverrideModal
        enableEdit={enableEdit}
        visible={isOpenCreateModal}
        onCloseModal={setIsOpenCreateModal}
      />
*/
