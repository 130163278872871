import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestUpdateLaneOverride,
  successUpdateLaneOverride,
  failureUpdateLaneOverride,
} from '../duck/updateLaneOverride.duck'

function* updateLaneOverrideAsync(data) {
  try {
    const response = yield call(rules.updateLaneOverride, data.payload.data)
    yield put(successUpdateLaneOverride(response))
  } catch (error) {
    yield put(failureUpdateLaneOverride(error.response.data.message))
  }
}

function* watchUpdateLaneOverrideAsync() {
  yield takeLatest(requestUpdateLaneOverride, updateLaneOverrideAsync)
}

export default watchUpdateLaneOverrideAsync
