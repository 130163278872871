import React from 'react'
import { useHistory } from 'react-router-dom'
import TitleList from 'components/TitleList'
import Tabs from 'antd/lib/tabs'
import ListMultiStep from 'containers/rules/ListMultiStep'
import ListRuleset from 'containers/rules/Ruleset/ListRuleset'
import ListRules from '../ListRules/ListRules'
import { StyledDiv, StyledTabs } from './styles'
import MasterRules from '../MasterRules'
import LaneOverrides from '../LaneOverride'

export default function TabsRules({ displayTitle = true, enableEdit = true }) {
  const history = useHistory()
  const handleTabClick = (key) => {
    history.push(`/${key}`)
  }
  const path = displayTitle ? 'rules' : 'customer'
  const masterRules = `${path}/master-rules`
  const rulesList = `${path}/list`
  const multiStep = `${path}/multi-step`
  const ruleSets = `${path}/ruleset`
  const laneOverride = `${path}/lane-overrides`

  return (
    <>
      {displayTitle && (
        <StyledDiv>
          <TitleList title="Rules" />
        </StyledDiv>
      )}
      <StyledTabs
        defaultActiveKey="history"
        onChange={handleTabClick}
        width="96%"
        tabBarStyle={{ margin: '0px 0px 0px 2%', color: '#002555', fontWeight: 600, marginBottom: 15 }}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Master Rules" key={masterRules}>
          <MasterRules enableEdit={enableEdit} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Customer Specific Rules" key={rulesList}>
          <ListRules enableEdit={enableEdit} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Multi-Step Rules" key={multiStep}>
          <ListMultiStep enableEdit={enableEdit} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="RuleSets" key={ruleSets}>
          <ListRuleset enableEdit={enableEdit} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Lane Overrides" key={laneOverride}>
          <LaneOverrides enableEdit={enableEdit} />
        </Tabs.TabPane>
      </StyledTabs>
    </>
  )
}
