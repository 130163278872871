import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect} from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Tooltip from 'antd/lib/tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { DatePicker } from 'antd'
import notify from 'utils/notify'
import PrimarySubmit from 'components/PrimarySubmit'
import TextInput from 'components/Form/TextInput'
import SelectInput from 'components/Form/SelectInput'
import useAutocomplete from 'hooks/useAutocomplete'
import LineSelectCity from 'components/Input/InputLineSelectCity/Index'
import Select from 'antd/lib/select'
import {
  requestGetZipHere,
} from 'store/duck/getZipHere.duck'
import { requestCreateLaneOverride, resetCreateLaneOverride} from 'store/duck/createLaneOverride.duck'
import { requestGetLaneOverrides } from 'store/duck/getLaneOverrides.duck'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import colors from 'providers/theme/config/colorPallete'
import Checkbox from 'antd/lib/checkbox'
import Text from 'antd/lib/typography/Text'
import { StyledModal} from './styles'
import AssignCustomers from './AssignCustomers'





const formName = 'create-lane-override-form'

const defaultValues = {
  origin: '',
  destination: '',
  equipmentType: '',
  ValidRange: '',
  exparation: '',
  customers: [],
  amount: '',
}

const CreateLaneOverrideForm = ({ onSubmit }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { 
    trucktypes, 
    customerSuccess,
  } = useSelector((state) => ({
    trucktypes: state.getTruckTypes.trucktypes,
    customerSuccess: state.getCustomers.success,
  }))
  const { customers } = useSelector((state) => state.getCustomers)
  const [dataDestination, setPlaceDestination, handleSelect, fetchingData] = useAutocomplete()
  const [assignedCustomers, setAssignedCustomers] = useState([])
  const[specialServices, setSpecialServices] = useState(false)
  const[customerMargin, setCustomerMargin] = useState(true)

  const handleSubmit = (formProps) => {
    onSubmit({ ...formProps, customers: assignedCustomers, addSpecialServices: specialServices})
  }

  const handleCity = (value, index) => {
    dispatch(requestGetZipHere(value, index))
  }
  useEffect(() => {
    dispatch(requestGetCustomers())
    // if edit user and success set form values here
  }, [dispatch]);



  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={handleSubmit}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row gutter={[26, 10]}>
          <Col span={24}>
            <div
              style={{
                //
                fontSize: '15px',
                color: colors.dark_grey.default,
                // ,
              }}
            >
              {` (The 3 digits of the zipcode are used to determine locations)`}
            </div>
          </Col>
          <Col span={24}>
            <LineSelectCity
              dataTestid="Origin"
              name="originCity"
              label="Origin"
              onSearch={setPlaceDestination}
              options={dataDestination}
              onChange={handleCity}
              index={0}
              fetchingData={fetchingData}
            />
          </Col>
          <Col span={24}>
            <LineSelectCity
              dataTestid="Destination"
              name="destinationCity"
              label="Destination"
              onSearch={setPlaceDestination}
              options={dataDestination}
              onChange={handleCity}
              index={0}
              fetchingData={fetchingData}
            />
          </Col>
          <Col span={24}>
            <SelectInput
              label="Equipment *"
              name="equipmentType"
              rules={[{ required: true, message: 'Please select an equipment' }]}
            >
              {trucktypes.map((truck) => (
                <Select.Option key={truck._id} value={truck._id}>
                  {truck.name}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col span={12}>
            <Form.Item name="ValidRange" label="Effective pickup dates">
              <DatePicker.RangePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expirationDate" label="Exparation Date">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={24}>
          { customerSuccess &&
            <AssignCustomers
              customers={customers}
              resetFormValue={() => form.setFieldsValue({ defaultCustomer: '' })}
              handleValues={setAssignedCustomers}
            />}
          </Col>
          <Col span={12}>
            <Checkbox
              checked={specialServices}
              onChange={() => {
                setSpecialServices((prev) => !prev)
              }}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Add Specal Services</Text>
            </Checkbox>
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={customerMargin}
                onChange={() => {
                  setCustomerMargin((prev) => !prev)
                }}
              >
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Add Customer Margin</Text>
              </Checkbox>
              <Tooltip title="This will allow customer flat and margin to be applied on top of this lane override.">
                <AiOutlineInfoCircle style={{ marginLeft: 8, color: '#545454', fontSize: 16 }} />
              </Tooltip>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                //
                fontSize: '15px',
                color: colors.dark_grey.default,
                // ,
              }}
            >
              {` (Amount regards the flat rate a lane should be priced at ignoring the pricing engine)`}
            </div>
          </Col>
          <Col span={24}>
            <TextInput label="Amount *" name="value" rules={[{
              required: true,
              message: 'This field is required',
            }]} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

const CreateLaneOverrideModal = ({
  visible,
  onCloseModal,
  // enableEdit = true,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const {
    success: successCreateLaneOverride,
    error: errorCreateLaneOverride,
    message: errorMessageCreateLaneOverride,
  } = useSelector((state) => state.createLaneOverride)
  
  const handleComplete = (rawDataToSubmit) => {
    // debugger
    delete rawDataToSubmit.defaultCustomer
    setLoading(true)
    dispatch(requestCreateLaneOverride(rawDataToSubmit))
  }

  useEffect(() => {
    if (successCreateLaneOverride || errorCreateLaneOverride) {
      if (successCreateLaneOverride) {
        notify('success', 'Lane Override created successfully!')
        onCloseModal()

        dispatch(requestGetLaneOverrides())
      }
      if (errorCreateLaneOverride) {
        notify('error', `Lane Override creation Error! ${errorMessageCreateLaneOverride}`)
      }

      setLoading(false)
      dispatch(resetCreateLaneOverride())
    }
  }, [errorCreateLaneOverride, errorMessageCreateLaneOverride, successCreateLaneOverride, dispatch, onCloseModal])

  return (
   
    <StyledModal
      title="Create Lane Override"
      width={500}
      visible={visible}
      onCancel={onCloseModal}
      bodyStyle={{ padding: '10px 25px 0px' }}
      centered
      footer={
        <div>
          <PrimarySubmit label='Save Lane Override' formName={formName} loading={loading} />
        </div>
      }
      destroyOnClose
    >
      <CreateLaneOverrideForm onSubmit={handleComplete}/>
    </StyledModal>
  )
}
export default CreateLaneOverrideModal
