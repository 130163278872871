import styled from 'styled-components'
import Badge from 'antd/lib/badge'


const stylesByStatus = {
  Activated: {
    count: 'Activated',
    backgroundColor: '#00C48C',
    color: '#FFFFFF',
  },
  Expired: {
    count: 'Expired',
    backgroundColor: '#F85359',
    color: '#FFFFFF',
  },
  Paused: {
    count: 'Paused',
    backgroundColor: '#cfb53b',
    color: '#FFFFFF',
  },
  Expiring: {
    count: 'Expiring',
    backgroundColor: '#F7981C',
    color: '#FFFFFF',
  },
}


const StyledBadge = styled(Badge).attrs((props) => {
  const variant = stylesByStatus[props.variant] ?? stylesByStatus.Activated

  return {
    count: variant.count,
    style: {
      backgroundColor: variant.backgroundColor,
      color: variant.color,
      fontWeight: 400,
      fontSize:16,
    },
  }
})``

export default StyledBadge