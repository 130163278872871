import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestCreateLaneOverride, successCreateLaneOverride, failureCreateLaneOverride } from 'store/duck/createLaneOverride.duck'

function* createLaneOverrideRequestAsync(data) {
  try {
    const response = yield call(rules.createLaneOverride, data.payload.data)
    yield put(successCreateLaneOverride(response, 'success'))
  } catch (error) {
    yield put(failureCreateLaneOverride(error.response.data.message))
  }
}

function* watchCreateLaneOverrideAsync() {
  yield takeLatest(requestCreateLaneOverride, createLaneOverrideRequestAsync)
}

export default watchCreateLaneOverrideAsync
