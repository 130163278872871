import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  data: {},
}

export const {
  requestDeleteLaneOverride,
  successDeleteLaneOverride,
  failureDeleteLaneOverride,
  clearDeleteLaneOverride,
} = createActions({
  REQUEST_DELETE_LANE_OVERRIDE: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_DELETE_LANE_OVERRIDE: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_LANE_OVERRIDE: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_LANE_OVERRIDE: () => ({
    successDelete: false,
    error: false,
    data: {},
    message: '',
    fetching: false,
  }),
})

const deleteLaneOverride = handleActions(
  {
    [requestDeleteLaneOverride]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.data = data
    }),
    [successDeleteLaneOverride]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteLaneOverride]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteLaneOverride]: produce((draft, { payload: { message, successDelete, fetching, error, data } }) => {
      draft.message = message
      draft.successDelete = successDelete
      draft.fetching = fetching
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default deleteLaneOverride
